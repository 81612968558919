import { useState, useEffect } from 'react'
import { Button, Box, Spinner, Card, CardBody, CardFooter, CardHeader, Anchor, Text, Table, TableBody, TableHeader, TableRow, TableCell, FormField, Select } from 'grommet'

import ReportNavigation from './ReportNavigation'
import BoardFilter from './BoardFilter'

import { combinedScoresData } from '../helpers'

import firebase from 'firebase/compat/app'

const SummaryBoard = ({ user, firestore }) => {
  const [dashDataTeams, setDashDataTeams] = useState()
  const [dashDataTeamsSub, setDashDataTeamsSub] = useState()
  const [dashDataIndv, setDashDataIndv] = useState()
  const [dashDataIndvSub, setDashDataIndvSub] = useState()
  const [filterSet, setFilterSet] = useState()
  const [totalTeams, setTotalTeams] = useState()
  const [currSize, setCurrSize] = useState()
  const [showdash, setShowDash] = useState();

  const [leafCollection, setLeafCollection] = useState("teams");

  const updateTotalCount = async() => {
    const totalTeams = await firestore.collection('summary').where(firebase.firestore.FieldPath.documentId(), '==', 'teams').get()
    totalTeams.docs.forEach(snap => {
      snap.data() && setTotalTeams(snap.data().count)
    })
  }

  useEffect(() => {
    let isCancelled = false

    const fetchDashboard = async () => {
      const wskirtanFilters = localStorage.getItem('__WSKIRTAN_FILTERS')
      if (wskirtanFilters && JSON.parse(wskirtanFilters)) {
        return
      }
      filterValues('', '', '', 20, '');
    }

    if (!isCancelled) {
      fetchDashboard()
    }

    // setShowDash(localStorage.getItem('showdash'));
    setShowDash(true);

    return () => {
      isCancelled = true
    }
  }, [])

  const fetchData = async (collection, filter, fdate) => {
    let scoresDataDocs = [];

    if (fdate) {
      while (fdate.length > 0) {
        let fscoresQuery = firestore.collection('flydates')
          .where('datekey', 'in', fdate.slice(0, 10))
          .where('searchkey', '==', collection)

        if (filter.name) {
          fscoresQuery = fscoresQuery.where(filter.name, '==', filter.value)
        }

        let scoresData = await fscoresQuery.orderBy('score', 'desc').get()
        scoresData.docs.filter(doc => doc.data().score).forEach(doc => scoresDataDocs.push(doc.data()));

        fdate.splice(0, 10);
      }
    } else {
      let scoresQuery = firestore.collection(collection)
      if (filter.name) {
        scoresQuery = scoresQuery.where(filter.name, '==', filter.value)
      }
      let scoresData = await scoresQuery.orderBy('score', 'desc').get().limit(10)
      scoresDataDocs = scoresData.docs.filter(doc => doc.data().score).map(snap => {
        return { ...snap.data(), ...{ searchval: snap.id } }
      })
    }

    const combinedData = combinedScoresData('searchval', scoresDataDocs);

    return combinedData.slice(0, 10);
  }

  const filterValues = async (country, temple, hkcenter, count, fdate) => {
    let filter = {}
    if (hkcenter) {
      filter = {name: 'hkcenter', value: hkcenter}
    } else if (temple) {
      filter = {name: 'temple', value: temple}
    } else if (country) {
      filter = {name: 'country', value: country}
    }

    if (filter.name) {
      setFilterSet(filter)
    }

    fetchData('teams', filter, fdate).then((data) => {
      setDashDataTeams(data)
    });

    fetchData('team_subs', filter, fdate).then((data) => {
      setDashDataTeamsSub(data)
    });

    fetchData('scores', filter, fdate).then((data) => {
      setDashDataIndv(data)
    });

    fetchData('score_subs', filter, fdate).then((data) => {
      setDashDataIndvSub(data)
    });
  }

  return (<>
    <Card background='light-1' width='xxlarge' margin={{ horizontal: 'large', vertical: 'large' }}>
      <CardHeader pad='medium' background={'light-2'}>
        <Box direction="row" justify="center" align='center'>
          <Text weight='bold' size='large'>Summary</Text>
        </Box>
        <ReportNavigation />
      </CardHeader>
      {!showdash && <CardBody pad='medium'><Text>This report is frozen till we announce the results.</Text></CardBody>}
      {showdash && <CardBody pad='medium'>
        <BoardFilter firestore={firestore} filterValues={filterValues} collection={leafCollection} />

        <Box overflow={{ horizontal: 'auto' }}>
          <Box direction='row' gap='large'>
            <Box direction='column' margin={{ bottom: 'small' }} background={'graph-3'} pad={{ vertical: 'small', horizontal: 'small' }} round='small' width={{ min: 'medium' }}>
              <Text size='medium' weight="bold" margin={{ bottom: "small" }} textAlign="center">Top Teams</Text>
              {(!dashDataTeams) && <Spinner />}
              {dashDataTeams && (dashDataTeams.length === 0) && <Text>No data</Text>}
              {dashDataTeams && (dashDataTeams.length > 0) && <Table>
                <TableHeader>
                  <TableRow>
                    <TableCell scope='col' border='bottom'>Name</TableCell>
                    <TableCell scope='col' border='bottom' align='right'>Score</TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {dashDataTeams.map((data, index) => {
                    return(
                      <TableRow key={index} style={{ background: (user && user.uid === data.searchval ? '#efefef' : '') }}>
                        <TableCell style={{ paddingTop: '0.4em' }}>
                          <Anchor href={`/profile/${data.searchval || user.uid}`} label={ (index + 1) + '. ' + data.profileName } />
                        </TableCell>
                        <TableCell style={{ paddingTop: '0.4em' }} align='right'>{parseFloat(data.score).toFixed(2) || '-'}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>}
            </Box>


            <Box direction='column' margin={{ bottom: 'small' }} background={'neutral-2'} pad={{ vertical: 'small', horizontal: 'small' }} round='small' width={{ min: 'medium' }}>
              <Text size='medium' weight="bold" margin={{ bottom: "small" }} textAlign="center">Top Teams [Sub]</Text>
              {(!dashDataTeamsSub) && <Spinner />}
              {dashDataTeamsSub && (dashDataTeamsSub.length === 0) && <Text>No data</Text>}
              {dashDataTeamsSub && (dashDataTeamsSub.length > 0) && <Table>
                <TableHeader>
                  <TableRow>
                    <TableCell scope='col' border='bottom'>Name</TableCell>
                    <TableCell scope='col' border='bottom' align='right'>Score</TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {dashDataTeamsSub.map((data, index) => {
                    return(
                      <TableRow key={index} style={{ background: (user && user.uid === data.searchval ? '#efefef' : '') }}>
                        <TableCell style={{ paddingTop: '0.4em' }}>
                          <Anchor href={`/profile/${data.searchval || user.uid}`} label={ (index + 1) + '. ' + data.profileName } />
                        </TableCell>
                        <TableCell style={{ paddingTop: '0.4em' }} align='right'>{parseFloat(data.score).toFixed(2) || '-'}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>}
            </Box>


            <Box direction='column' margin={{ bottom: 'small' }} background={'accent-3'} pad={{ vertical: 'small', horizontal: 'small' }} round='small' width={{ min: 'medium' }}>
              <Text size='medium' weight="bold" margin={{ bottom: "small" }} textAlign="center">Top Individuals</Text>
              {(!dashDataIndv) && <Spinner />}
              {dashDataIndv && (dashDataIndv.length === 0) && <Text>No data</Text>}
              {dashDataIndv && (dashDataIndv.length > 0) && <Table>
                <TableHeader>
                  <TableRow>
                    <TableCell scope='col' border='bottom'>Name</TableCell>
                    <TableCell scope='col' border='bottom' align='right'>Score</TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {dashDataIndv.map((data, index) => {
                    return(
                      <TableRow key={index} style={{ background: (user && user.uid === data.searchval ? '#efefef' : '') }}>
                        <TableCell style={{ paddingTop: '0.4em' }}>
                          <Anchor href={`/profile/${data.searchval || user.uid}`} label={ (index + 1) + '. ' + data.profileName } />
                        </TableCell>
                        <TableCell style={{ paddingTop: '0.4em' }} align='right'>{parseFloat(data.score).toFixed(2) || '-'}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>}
            </Box>


            <Box direction='column' margin={{ bottom: 'small' }} background={'accent-4'} pad={{ vertical: 'small', horizontal: 'small' }} round='small' width={{ min: 'medium' }}>
              <Text size='medium' weight="bold" margin={{ bottom: "small" }} textAlign="center">Top Individuals [Sub]</Text>
              {(!dashDataIndvSub) && <Spinner />}
              {dashDataIndvSub && (dashDataIndvSub.length === 0) && <Text>No data</Text>}
              {dashDataIndvSub && (dashDataIndvSub.length > 0) && <Table>
                <TableHeader>
                  <TableRow>
                    <TableCell scope='col' border='bottom'>Name</TableCell>
                    <TableCell scope='col' border='bottom' align='right'>Score</TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {dashDataIndvSub.map((data, index) => {
                    return(
                      <TableRow key={index} style={{ background: (user && user.uid === data.searchval ? '#efefef' : '') }}>
                        <TableCell style={{ paddingTop: '0.4em' }}>
                          <Anchor href={`/profile/${data.searchval || user.uid}`} label={ (index + 1) + '. ' + data.profileName } />
                        </TableCell>
                        <TableCell style={{ paddingTop: '0.4em' }} align='right'>{parseFloat(data.score).toFixed(2) || '-'}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>}
            </Box>


          </Box>
        </Box>
      </CardBody>}

      <CardFooter pad='medium' background='light-2'>
        <Box flex direction='row' align='center' gap='medium' justify='between'>
          <Text></Text>
          <Button primary size='medium' href='/sankirtana' label='Add your score' />
        </Box>
      </CardFooter>
    </Card>
  </>)

}

export default SummaryBoard