import { useState, useEffect } from 'react'
import { Spinner, Card, CardBody, CardFooter, CardHeader, Text, Table, TableBody, TableHeader, TableRow, TableCell, Box, Button, Anchor } from 'grommet'

import ReportNavigation from './ReportNavigation'
import BoardFilter from './BoardFilter'

import { combinedScoresData } from '../helpers';

const TempleBoard = ({ firestore }) => {
  const [dashData, setDashData] = useState()

  useEffect(() => {
    let isCancelled = false

    const fetchDashboard = async () => {
      const wskirtanFilters = localStorage.getItem('__WSKIRTAN_FILTERS')
      if (wskirtanFilters && JSON.parse(wskirtanFilters)) {
        return
      }
      filterValues('', '', '', 20, '');
    }

    fetchDashboard()

    return () => {
      isCancelled = true
    }

  }, [])

  const filterValues = async (country, temple, hkcenter, count, fdate) => {
    let filter = {}
    if (country) {
      filter = {name: 'country', value: country}
    }

    let scoresDataDocs = [];

    if (fdate) {
      while (fdate.length > 0) {
        let fscoresQuery = firestore.collection('flydates')
          .where('datekey', 'in', fdate.slice(0, 10))
          .where('searchkey', '==', 'temple');

        if (filter.name) {
          fscoresQuery = fscoresQuery.where(filter.name, '==', filter.value)
        }

        let scoresData = await fscoresQuery.orderBy('score', 'desc').get()
        scoresData.docs.filter(doc => doc.data().score).forEach(doc => scoresDataDocs.push(doc.data()));

        fdate.splice(0, 10);
      }
    } else {
      let scoresQuery = firestore.collection('temples')
      if (filter.name) {
        scoresQuery = scoresQuery.where(filter.name, '==', filter.value)
      }
      let scoresData = await scoresQuery.orderBy('score', 'desc').get()
      scoresDataDocs = scoresData.docs.filter(doc => doc.data().score).map(snap => {
        return { ...snap.data(), ...{ searchval: snap.id } }
      })
    }

    const combinedData = combinedScoresData('searchval', scoresDataDocs);
    setDashData(combinedData)
  }

  return (<>
    <Card background='light-1' width='xlarge' margin={{ horizontal: 'large', vertical: 'large' }}>
      <CardHeader pad='medium' background={'light-2'}>
        <Text weight='bold' size='large'>Temples' report</Text>
        <ReportNavigation />
      </CardHeader>
      <CardBody pad='medium'>
        <BoardFilter firestore={firestore} filterValues={filterValues} collection={'temples'} />
        <Box overflow={{ horizontal: 'auto' }}>
          {(!dashData) && <Spinner />}
          {dashData && (dashData.length === 0) && <Text>No data</Text>}
          {dashData && (dashData.length > 0) && <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope='col' border='bottom'>Name</TableCell>
                <TableCell scope='col' border='bottom'>Country</TableCell>
                <TableCell scope='col' border='bottom'>M-Big</TableCell>
                <TableCell scope='col' border='bottom'>Big</TableCell>
                <TableCell scope='col' border='bottom'>Medium</TableCell>
                <TableCell scope='col' border='bottom'>Small</TableCell>
                <TableCell scope='col' border='bottom' align='right'>Score</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {dashData.map((data, index) => {
                return(
                  <TableRow key={index}>
                    <TableCell style={{ paddingTop: '0.4em' }}>
                      <Anchor href={`/graph/${data.searchkey || 'temple'}/${data.searchval}`} >
                        {data.searchval}
                      </Anchor>
                    </TableCell>
                    <TableCell style={{ paddingTop: '0.4em' }}>{data.country}</TableCell>
                    <TableCell style={{ paddingTop: '0.4em' }}>{data['m-big'] || '-'}</TableCell>
                    <TableCell style={{ paddingTop: '0.4em' }}>{data.big || '-'}</TableCell>
                    <TableCell style={{ paddingTop: '0.4em' }}>{data.medium || '-'}</TableCell>
                    <TableCell style={{ paddingTop: '0.4em' }}>{data.small || '-'}</TableCell>
                    <TableCell style={{ paddingTop: '0.4em' }} align='right'>{parseFloat(data.score).toFixed(2) || '-'}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>}
        </Box>
      </CardBody>

      <CardFooter pad='medium' background='light-2'>
        <Box flex direction='row' align='center' gap='medium' justify='between'>
          <Text></Text>
          <Button primary size='medium' href='/sankirtana' label='Add your score' />
        </Box>
      </CardFooter>
    </Card>
  </>)
}

export default TempleBoard