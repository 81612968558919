import { useState, useEffect } from 'react'
import { Button, Box, Spinner, Card, CardBody, CardFooter, CardHeader, Anchor, Text, Table, TableBody, TableHeader, TableRow, TableCell, FormField, Select } from 'grommet'

import ReportNavigation from './ReportNavigation'
import BoardFilter from './BoardFilter'

import { combinedScoresData } from '../helpers'

import firebase from 'firebase/compat/app'

const SankirtanBoard = ({ user, firestore }) => {
  const [dashData, setDashData] = useState()
  const [filterSet, setFilterSet] = useState()
  const [totalTeams, setTotalTeams] = useState()
  const [currSize, setCurrSize] = useState()
  const [showdash, setShowDash] = useState();

  const [leafCollection, setLeafCollection] = useState("teams");

  const updateTotalCount = async() => {
    const totalTeams = await firestore.collection('summary').where(firebase.firestore.FieldPath.documentId(), '==', 'teams').get()
    totalTeams.docs.forEach(snap => {
      snap.data() && setTotalTeams(snap.data().count)
    })
  }

  useEffect(() => {
    let isCancelled = false

    const fetchDashboard = async () => {
      const wskirtanFilters = localStorage.getItem('__WSKIRTAN_FILTERS')
      if (wskirtanFilters && JSON.parse(wskirtanFilters)) {
        return
      }
      filterValues('', '', '', 20, '');
    }

    if (!isCancelled) {
      fetchDashboard()
    }

    // setShowDash(localStorage.getItem('showdash'));
    setShowDash(true);

    return () => {
      isCancelled = true
    }
  }, [])

  const filterValues = async (country, temple, hkcenter, count, fdate) => {
    let filter = {}
    if (hkcenter) {
      filter = {name: 'hkcenter', value: hkcenter}
    } else if (temple) {
      filter = {name: 'temple', value: temple}
    } else if (country) {
      filter = {name: 'country', value: country}
    }

    if (filter.name) {
      setFilterSet(filter)
    }

    let scoresDataDocs = [];

    if (fdate) {

      while (fdate.length > 0) {
        let fscoresQuery = firestore.collection('flydates')
          .where('datekey', 'in', fdate.slice(0, 10))
          .where('searchkey', '==', leafCollection)

        if (filter.name) {
          fscoresQuery = fscoresQuery.where(filter.name, '==', filter.value)
        }

        let scoresData = await fscoresQuery.orderBy('score', 'desc').get()
        scoresData.docs.filter(doc => doc.data().score).forEach(doc => scoresDataDocs.push(doc.data()));

        fdate.splice(0, 10);
      }
    } else {
      let scoresQuery = firestore.collection(leafCollection)
      if (filter.name) {
        scoresQuery = scoresQuery.where(filter.name, '==', filter.value)
      }
      let scoresData = await scoresQuery.orderBy('score', 'desc').get()
      scoresDataDocs = scoresData.docs.filter(doc => doc.data().score).map(snap => {
        return { ...snap.data(), ...{ searchval: snap.id } }
      })
    }

    const combinedData = combinedScoresData('searchval', scoresDataDocs);

    setCurrSize(combinedData.length)
    updateTotalCount()

    setDashData(combinedData)
  }

  return (<>
    <Card background='light-1' width='xxlarge' margin={{ horizontal: 'large', vertical: 'large' }}>
      <CardHeader pad='medium' background={'light-2'}>
        <Box direction="row" justify="center" align='center'>
          <Text weight='bold' size='large'>Teams'</Text>
          <FormField margin={{ horizontal: 'small', top: 'none' }} width={"xsmall"}>
            <Select
              size='small'
              options={['Reg.', 'Sub.']}
              value={leafCollection === "teams" ? "Reg." : "Sub."}
              onChange={({ option }) => {
                setLeafCollection((option === "Reg." ? "teams" : "team_subs"));
                setDashData();
              }}
            />
          </FormField>
          <Text weight='bold' size='large'>report</Text>
        </Box>
        <ReportNavigation />
      </CardHeader>
      {!showdash && <CardBody pad='medium'><Text>This report is frozen till we announce the results.</Text></CardBody>}
      {showdash && <CardBody pad='medium'>
        <BoardFilter firestore={firestore} filterValues={filterValues} collection={leafCollection} />

        <Box overflow={{ horizontal: 'auto' }}>
          {(!dashData) && <Spinner />}
          {dashData && (dashData.length === 0) && <Text>No data</Text>}
          {dashData && (dashData.length > 0) && <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope='col' border='bottom'>Name</TableCell>
                <TableCell scope='col' border='bottom'>Temple</TableCell>
                <TableCell scope='col' border='bottom'>Center</TableCell>
                <TableCell scope='col' border='bottom'>Country</TableCell>
                <TableCell scope='col' border='bottom'>M-Big</TableCell>
                <TableCell scope='col' border='bottom'>Big</TableCell>
                <TableCell scope='col' border='bottom'>Medium</TableCell>
                <TableCell scope='col' border='bottom'>Small</TableCell>
                <TableCell scope='col' border='bottom' align='right'>Score</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {dashData.map((data, index) => {
                return(
                  <TableRow key={index} style={{ background: (user && user.uid === data.searchval ? '#efefef' : '') }}>
                    <TableCell style={{ paddingTop: '0.4em' }}>
                      <Anchor href={`/profile/${data.searchval || user.uid}`} label={ (index + 1) + '. ' + data.profileName } />
                    </TableCell>
                    <TableCell style={{ paddingTop: '0.4em' }}>{data.temple}</TableCell>
                    <TableCell style={{ paddingTop: '0.4em' }}>{data.hkcenter}</TableCell>
                    <TableCell style={{ paddingTop: '0.4em' }}>{data.country}</TableCell>
                    <TableCell style={{ paddingTop: '0.4em' }}>{data['m-big'] || '-'}</TableCell>
                    <TableCell style={{ paddingTop: '0.4em' }}>{data.big || '-'}</TableCell>
                    <TableCell style={{ paddingTop: '0.4em' }}>{data.medium || '-'}</TableCell>
                    <TableCell style={{ paddingTop: '0.4em' }}>{data.small || '-'}</TableCell>
                    <TableCell style={{ paddingTop: '0.4em' }} align='right'>{parseFloat(data.score).toFixed(2) || '-'}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>}
          {!!currSize && !!totalTeams && <Text margin={{ top: 'medium' }} size='small' color={'text-xweak'} style={{ textTransform: 'uppercase' }}>Showing {currSize} of {totalTeams} total teams</Text>}
        </Box>
      </CardBody>}

      <CardFooter pad='medium' background='light-2'>
        <Box flex direction='row' align='center' gap='medium' justify='between'>
          <Text></Text>
          <Button primary size='medium' href='/sankirtana' label='Add your score' />
        </Box>
      </CardFooter>
    </Card>
  </>)

}

export default SankirtanBoard