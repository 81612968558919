import { Box, Grommet, ResponsiveContext, Spinner, Footer, Text, Anchor } from 'grommet'

import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

import { useAuthState } from 'react-firebase-hooks/auth'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import Nav from './components/Nav'
import Me from './components/Me'
import Approval from './components/Approval'
import Sankirtana from './components/Sankirtana'
import SankirtanaAdvForm from './components/SankirtanaAdvForm'
import NotFound from './components/NotFound'
import SankirtanaBoard from './components/SankirtanaBoard'
import Profile from './components/Profile'
import TempleBoard from './components/TempleBoard'
import CountryBoard from './components/CountryBoard'
import CenterBoard from './components/CenterBoard'
import Summary from './components/Summary'
import TeamsBoard from './components/TeamsBoard'
import WsGraph from './components/WsGraph'
import SummaryBoard from './components/SummaryBoard'

import Console from './console';

// Staging creds:
// firebase.initializeApp({
//   apiKey: "AIzaSyA4oQcCB0dlq3De45U9ZDjCoEgFIILs3kw",
//   authDomain: "o.wskirtan.live",
//   projectId: "sankirtan-267d8",
//   storageBucket: "sankirtan-267d8.appspot.com",
//   messagingSenderId: "1092108591355",
//   appId: "1:1092108591355:web:bc9ffd287328579e76105e"
// })

// Production creds:
firebase.initializeApp({
  apiKey: "AIzaSyCgsTVBzdCjgkvYTBYmzraBSsObhZW8zQw",
  authDomain: "gauth.wskirtan.live",
  projectId: "world-sankirtana",
  storageBucket: "world-sankirtana.appspot.com",
  messagingSenderId: "443379581569",
  appId: "1:443379581569:web:6f98b08aacefbccc284380"
})

const auth = firebase.auth()
const firestore = firebase.firestore()
// firestore.useEmulator("localhost", 8080);

console.log(`%c
     ██  █████  ██     ███████ ██████  ██ ██       █████      ██████  ██████   █████  ██████  ██   ██ ██    ██ ██████   █████  ██████   █████  
     ██ ██   ██ ██     ██      ██   ██ ██ ██      ██   ██     ██   ██ ██   ██ ██   ██ ██   ██ ██   ██ ██    ██ ██   ██ ██   ██ ██   ██ ██   ██ 
     ██ ███████ ██     ███████ ██████  ██ ██      ███████     ██████  ██████  ███████ ██████  ███████ ██    ██ ██████  ███████ ██   ██ ███████ 
██   ██ ██   ██ ██          ██ ██   ██ ██ ██      ██   ██     ██      ██   ██ ██   ██ ██   ██ ██   ██ ██    ██ ██      ██   ██ ██   ██ ██   ██ 
 █████  ██   ██ ██     ███████ ██   ██ ██ ███████ ██   ██     ██      ██   ██ ██   ██ ██████  ██   ██  ██████  ██      ██   ██ ██████  ██   ██
`, 'color: #001542;')


function App() {
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const location = useLocation()

  Console(firestore);

  const theme = {
    global: {
      // https://v2.grommet.io/color
      colors: {
        brand: '#001542',
        'accent-1': '#FFB30D',
        focus: '#7A7A7A'
      },
      font: {
        family: 'Open Sans',
      },
    }
  };

  const signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    await firebase.auth().signInWithPopup(provider)
    if (location.pathname === '/me') {
      return
    }
    const user = auth.currentUser
    const profileRef = user && user.uid && firestore.collection('profiles').doc(user.uid)
    profileRef && await profileRef.get().then((profile) => {
      const profileData = profile.data()
      if (!profileData || !profileData.country || !profileData.temple || !profileData.hkcenter) {
        navigate('/me')
      }
    })
  }

  const signOut = () => {
    auth.currentUser && auth.signOut()
    navigate('/')
  }

  return (
    <Grommet theme={theme}>
      <ResponsiveContext.Consumer>
        {size => (
          <Box responsive={true}>
            <Nav user={user} userLoading={loading} signInWithGoogle={signInWithGoogle} />
            <Box responsive={true} direction='row' flex overflow={{ horizontal: 'auto' }} style={{ minHeight: '100vh' }}>
              <Box responsive={true} flex align='center' margin={{ horizontal: 'small' }}>
                <Routes>
                  <Route path="/" element={
                    <Summary firestore={firestore} showRegister={false} signInWithGoogle={signInWithGoogle} />
                  } />
                  
                  <Route path="/scores" element={
                    <SankirtanaBoard user={user} firestore={firestore} />
                  } />

                  <Route path="/teams" element={
                    <TeamsBoard user={user} firestore={firestore} />
                  } />

                  <Route path="/summary" element={
                    <SummaryBoard user={user} firestore={firestore} />
                  } />

                  <Route path="/centers" element={
                    <CenterBoard firestore={firestore} />
                  } />

                  <Route path="/temples" element={
                    <TempleBoard firestore={firestore} />
                  } />

                  <Route path="/countries" element={
                    <CountryBoard firestore={firestore} />
                  } />

                  <Route path="/me" element={
                    <>
                      {loading && <Spinner />}
                      {!loading && user && <Me user={user} firestore={firestore} signOut={signOut} />}
                      {!loading && !user && <NotFound signInWithGoogle={signInWithGoogle} />}
                    </>
                  } />

                  <Route path="/sankirtana" element={
                    <>
                      {loading && <Spinner />}
                      {!loading && user && (<>
                        <Sankirtana user={user} firestore={firestore} />
                        <SankirtanaAdvForm user={user} firestore={firestore} />
                      </>)}
                      {!loading && !user && <NotFound signInWithGoogle={signInWithGoogle} />}
                    </>
                  } />

                  <Route path="/approve" element={
                    <>
                      {loading && <Spinner />}
                      {!loading && user && (<>
                        <Approval user={user} firestore={firestore} />
                      </>)}
                      {!loading && !user && <NotFound signInWithGoogle={signInWithGoogle} />}
                    </>
                  } />

                  <Route path="/profile/:uid" element={
                    <Profile firestore={firestore} />
                  } />

                  <Route path="/graph/:key/:value" element={
                    <WsGraph firestore={firestore} />
                  } />

                  <Route path="*" element={
                    <NotFound />
                  } />
                </Routes>
              </Box>
            </Box>
            <Footer background="brand" pad="small">
              <Text size='small'>Maintained by <Anchor href='https://hkmmumbai.org' weight={'normal'}>HKM Mumbai</Anchor></Text>
              <Anchor size='small' weight={'normal'} label="Support us" href='https://rzp.io/l/world-sankirtana' target={'_blank'} />
            </Footer>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default App;