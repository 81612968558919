import { useState, useEffect } from 'react'
import { Card, Anchor, Text, Table, TableRow, TableCell, Image, TableBody, CardBody, CardFooter, CardHeader, Box, Spinner, Avatar, Button } from 'grommet'

import firebase from 'firebase/compat/app'

const Summary = ({ firestore, showRegister, signInWithGoogle }) => {
  const [dashData, setDashData] = useState()
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  useEffect(() => {
    let isCancelled = false

    const fetchData = async () => {
      const scoresQuery = firestore.collection('summary').where(firebase.firestore.FieldPath.documentId(), '==', 'scores')
      const scoresData = await scoresQuery.get()

      if (!isCancelled) {

        scoresData.docs.forEach(snap => {
          setDashData(snap.data())
        })
      }
    }

    fetchData()

    return () => {
      isCancelled = true
    }
  }, [])

  return (<>
    <Card background="light-1" width={'large'} margin={{ horizontal: 'large', vertical: 'large' }}>
      <CardHeader pad='medium' background={'light-2'} justify='center' direction='column'>
        <Text weight='bolder' size='large' style={{ textAlign: 'center' }}>An offering to Srila Prabhupada</Text>
        <Text size='large' weight='normal'>Total books distributed</Text>
      </CardHeader>
      <CardBody pad='medium' overflow={{ horizontal: 'auto' }} align='center'>
        {<Table><TableBody>
          <TableRow>
            <TableCell align='end'><Text size={'large'} weight={'normal'}>All Time:</Text></TableCell>
            <TableCell>
              <Text size={'large'} weight={'normal'}>
                {!dashData ? <Spinner /> : parseInt(dashData['m-big'] || 0) + parseInt(dashData['big'] || 0) + parseInt(dashData['medium'] || 0) + parseInt(dashData['small'] || 0)}
              </Text>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='end'><Text size={'large'} weight={'normal'}>This Year:</Text></TableCell>
            <TableCell><Text size={'large'} weight={'normal'}>{!dashData ? <Spinner /> : dashData[`Y${new Date().getFullYear()}`]}</Text></TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='end'><Text size={'large'} weight={'normal'}>This Month:</Text></TableCell>
            <TableCell><Text size={'large'} weight={'normal'}>{!dashData ? <Spinner /> : dashData[`M${new Date().getFullYear()}${months[new Date().getMonth()].toUpperCase()}`]}</Text></TableCell>
          </TableRow>
        </TableBody></Table>}
        {showRegister && (<>
          <Button onClick={signInWithGoogle} primary label="Register now for book marathon 2023" margin={{ top: "medium" }} />
        </>)}
      </CardBody>
      <CardFooter background={'light-2'}>
        <Box flex direction='column' pad={'large'}>
          <Avatar src='/prabhupada.jpg' size='4xl' alignSelf='center'/>
          <Text margin={{ vertical: 'small' }} size='small'>We offer our respectful obeisances unto His Divine Grace A. C. Bhaktivedanta Swami Prabhupada, who is very dear to Lord Krishna, having taken shelter at His lotus feet.</Text>
          <Text size='small'>Our respectful obeisances are unto you, O spiritual master, servant of Sarasvati Goswami. You are kindly preaching the message of Lord Caitanya and delivering the Western countries, which are filled with impersonalism and voidism.</Text>
        </Box>
      </CardFooter>
    </Card>

    <Card background="light-2" width={'large'} margin={{ horizontal: 'large', bottom: 'large' }} pad='medium' align='center'>
      <Anchor href='/sankirtana' size='medium' label='Add your sankirtana score' />
    </Card>

    <Card background="light-2" width={'large'} margin={{ horizontal: 'large', bottom: 'large' }} pad='medium' align='center'>
      <Anchor href='/summary' size='medium' label="View scores" />
    </Card>

    <Card background="light-1" width={'large'} margin={{ horizontal: 'large', bottom: 'large' }}>
      <CardHeader pad='medium' background={'light-2'}>
        <Text size='medium' weight='bold'>Scoring Schema</Text>
      </CardHeader>
      <CardBody pad='medium' overflow={{ horizontal: 'auto' }}>
        <Image
          fit="contain"
          fill='horizontal'
          src="/scrore-schema.jpeg"
        />
      </CardBody>
      <CardFooter background={'light-2'}>
        <Box flex direction='column' pad={'xsmall'}></Box>
      </CardFooter>
    </Card>
  </>)
}

export default Summary